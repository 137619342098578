<template>
  <div> 
  <b-row class="match-height" v-if="surveyAbs!=null&&surveyAbs!=undefined">
    <b-col
      cols="12"
    >
      <p class="mb-2">
        <span>{{ surveytitle }} Results</span>
        
      </p>
    </b-col>
    
  
    <b-col md="4">
    
       <apex-radar-chart  title="Survey Average"  :apexChartData="globalOverviewChart"  />
   
      </b-col>
      <b-col md="4">
    
    <apex-radar-chart  title="Survey Average"  :apexChartData="globalAllOverviewChart"  />

   </b-col>
    <b-col md="4">
  
   <b-card
 
   
  >
    <b-card-header>
      <h4 class="mb-0">
        Attendee Overview
      </h4>
      <b-card-text class="font-medium-5 mb-0">
        <feather-icon
          icon="HelpCircleIcon"
          size="21"
          class="text-muted cursor-pointer"
        />
      </b-card-text>
    </b-card-header>

    <!-- apex chart -->
    <vue-apex-charts
      type="radialBar"
      height="245"
      class="my-2"
      :options="goalOverviewRadialBar"
      :series="AttendeeOverview.series"
    />
    <b-row class="text-center mx-0">
      <b-col
        cols="6"
        class="border-top border-right d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0">
          Completed
        </b-card-text>
        <h3 class="font-weight-bolder mb-0">
          {{ AttendeeOverview.completed }}
        </h3>
      </b-col>
 
      <b-col
        cols="6"
        class="border-top d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0">
          Pending
        </b-card-text>
        <h3 class="font-weight-bolder mb-0">
          {{ AttendeeOverview.inProgress }}
        </h3>
      </b-col>
    </b-row>
  </b-card>
    </b-col>

       <b-col
      cols="12"
    >
    
        <h2> Survey Items  </h2>
        
      
    </b-col>
   
  
    <b-col md="4">
      <v-select
          v-model="selected"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="surveyitemtitle"
          :options="options"
        />
       <apex-radar-chart :title="selectedTitle" :apexChartData="selectedChart"  />
    </b-col>
    
      
       
             <b-col md="4">

 <apex-radar-chart  ref="realtimeChart" :key="chartKey" :title="selectedAverageTitle" :apexChartData="radarChart"  />
    
             </b-col>

          
  
        <b-col md="4">
  <b-card
 
   
  >
    <b-card-header>
      <h4 class="mb-0">
        Attendee Overview
      </h4>
      <b-card-text class="font-medium-5 mb-0">
        <feather-icon
          icon="HelpCircleIcon"
          size="21"
          class="text-muted cursor-pointer"
        />
      </b-card-text>
    </b-card-header>

    <!-- apex chart -->
    <vue-apex-charts
      type="radialBar"
      height="245"
      class="my-2"
      :options="goalOverviewRadialBar"
      :series="goalOverview.series"
    />
    <b-row class="text-center mx-0">
      <b-col
        cols="6"
        class="border-top border-right d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0">
          Completed
        </b-card-text>
        <h3 class="font-weight-bolder mb-0">
          {{ goalOverview.completed }}
        </h3>
      </b-col>

      <b-col
        cols="6"
        class="border-top d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0">
          Pending
        </b-card-text>
        <h3 class="font-weight-bolder mb-0">
          {{ goalOverview.inProgress }}
        </h3>
      </b-col>
     
     

    </b-row>
  </b-card>



 </b-col>
    

  </b-row>
<b-row>
  <b-col
        cols="12"
        class="border-top d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0">
          Detail
        </b-card-text>
        <apex-data-bar-chart :key="chartKey" :item="selectedTitle" :data="attendteAnswers" v-if="attendteAnswers" />
      </b-col>
</b-row>
  </div>
  
</template>

<script>
import { BRow, BCol, BLink,  BCard, BCardHeader,   BCardText, } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import ApexLineChart from './ApexLineChart.vue'
import ApexLineAreaChart from './ApexLineAreaChart.vue'
import ApexDataBarChart from './ApexDataBarChart.vue'
import ApexBarChart from './ApexBarChart.vue'
import ApexCandlestickChart from './ApexCandlestickChart.vue'
import ApexScatterChart from './ApexScatterChart.vue'
import ApexHeatMapChart from './ApexHeatMapChart.vue'
import ApexDonutChart from './ApexDonutChart.vue'
import ApexRadialBarChart from './ApexRadialBarChart.vue'
import ApexRadarChart from './ApexRadarChart.vue'
import { $themeColors } from '@themeConfig'
import vSelect from 'vue-select'
import router from '@/router'
import store from '@/store'
import resultsStoreModule from './resultsStoreModule'
import { ref, onUnmounted } from '@vue/composition-api'
const chartColors = {
  column: {
    series1: '#826af9',
    series2: '#d2b0ff',
    bg: '#f8d3ff',
  },
  success: {
    shade_100: '#7eefc7',
    shade_200: '#06774f',
  },
  donut: {
    series1: '#ffe700',
    series2: '#00d4bd',
    series3: '#826bf8',
    series4: '#2b9bf4',
    series5: '#FFA1A1',
  },
  area: {
    series3: '#a4f8cd',
    series2: '#60f2ca',
    series1: '#2bdac7',
  },
}
const $strokeColor = '#ebe9f1'
const $textHeadingColor = '#5e5873'
const $goalStrokeColor2 = '#51e5a8'
export default {
  components: {
    BRow,
    BCol,
    BLink,  BCard, BCardHeader, BCardText,
VueApexCharts,vSelect,
    ApexLineChart,
    ApexLineAreaChart,
    ApexDataBarChart,
    ApexBarChart,
    ApexCandlestickChart,
    ApexScatterChart,
    ApexHeatMapChart,
    ApexDonutChart,
    ApexRadarChart,
    ApexRadialBarChart,
  },
  methods:{
     updateSelections(a) {
    
    //console.log(a);
  },
         fetchTags( ) {
      //console.log(" survey subjects fetch ");
      return new Promise((resolve, reject) => {
        axios
          .get('/survey/admin/getallquestiontags' )
          .then((response)=> { 
           
         
           var data=  response.data;
           var responseData=JSON.parse(  JSON.stringify( data));
          //console.log(responseData.data);
              this.categories=responseData.data;

                //console.log("incoming2  initialtag"+this.initialtag);

        if (this.initialtag==null||this.initialtag.length==0)
        {
            this.selectedinitialtag=this.categories[0];
        }
        else
        {
          //console.log("incoming initialtag"+this.initialtag);
        }
            }
            )
          .catch(error => reject(error))
      })
    },
  },
  created: function () {
  
    this.surveytitle=this.$route.query.t;
    this.surveyId=this.$route.query.s;

  },
 watch:{
  surveySubjectsAbs(newValue,OldValue){
    //console.log("changed");
    //console.log(newValue);
    //console.log(OldValue);
    if (OldValue==null||OldValue==undefined)
    {
      this.selectedItem =   this.surveySubjectsAbs[0];
    }
  }
  
 },
  computed:{
    
    AttendeeOverview() {

if ( this.surveyAttendeeResults==null||this.surveyAttendeeResults==undefined||this.surveyAttendeeResults=="" )
  {
      return { completed: '0',
inProgress: '0',
series: [0],};
  }
 

var data=this.surveyAttendeeResults;
//console.log("Calcualted Result");
//console.log(data);
 
var completed=data.Completed;
var disCompleted=data.INProg;
var comp=parseInt(completed);
var disomp=parseInt(disCompleted);
var cabs=parseInt( comp*100/(comp+disomp));



return {
completed: completed,
inProgress: disCompleted,
series: [cabs],
}
},
    goalOverview() {

      if ( this.selectedItem==null||this.selectedItem==undefined||this.selectedItem==""||this.selectedText=="")
        {
            return { completed: '0',
    inProgress: '0',
    series: [0],};
        }


      var values=this.selectedItem.map(a => a.attendeeAnswer);
      var tags=this.selectedItem.map(a => a.questionTag);
      //console.log(this.selectedItem);

      var data=this.selectedItem[0];
//console.log(data);
 var completed=data.Completed;
 var disCompleted=data.InProg;
var comp=parseInt(completed);
var disomp=parseInt(disCompleted);
var cabs=parseInt( comp*100/(comp+disomp));



      return {
    completed: completed,
    inProgress: disCompleted,
    series: [cabs],
      }
  },
  globalAllOverviewChart()
    {
      //console.log("---------------GLOBALL ALL----------------------------");
      //console.log(this.surveyAbs);
      if ( this.surveyAbs==null||this.surveyAbs==undefined )
        {
            return {};
        }
        else

        {
          //console.log(this.surveyAbs);
     
          const iterator1 = Object.keys(this.surveyAbs)  ;
          //console.log(iterator1);
      var seriCount=iterator1.length;
      var series=[];

      //console.log("---------------seriCount -----------------",seriCount);
       
var globalTags=[];

      for (var i=0;i<seriCount;i++)
      {
        var titles =iterator1[i];

        var tags=this.surveyAbs[titles];

        //console.log(tags);

        var values=tags.map(a => a.attendeeAnswer); 
      var attandeetags= tags.map(a => a.questionTag);
      globalTags=attandeetags;

        var item=
        {
          name: titles,
          data: values,
        }
        series.push(item);


      }
  //console.log("GLOBALL INITALZ");

      return {
    series:series
     , markers: {
    size: 5,
    hover: {
      size: 10
    }
  },
    chartOptions: {
      chart: {
        toolbar: {
          show: true,
        },
       
        dropShadow: {
          enabled: false,
          blur: 8,
          left: 1,
          top: 1,
          opacity: 0.2,
        },
      },
      legend: {
        show: true,
        fontSize: '14px',
        fontFamily: 'Montserrat',
      },
      yaxis: {
        show: true,
      },
      colors: [chartColors.donut.series1,chartColors.donut.series2,chartColors.donut.series4,chartColors.donut.series5, chartColors.donut.series3,chartColors.area.series1,chartColors.area.series2,chartColors.area.series3],
      xaxis: {
        categories: globalTags,
        labels: {
  show: true,
  style: {
    colors: ["#a8a8a8"],
    fontSize: "11px",
    fontFamily: 'Arial'
  }
},
      },
      fill: {
        opacity: [1, 0.8],
      },
      dataLabels: {
  enabled: false,
  background: {
    enabled: true,
    borderRadius:2,
  }
},
      stroke: {
        show: false,
        width: 0,
      },
      markers: {
        size: 0,
      },
      grid: {
        show: false,
      },
    },
  }
        }
       

    },
  globalOverviewChart()
    {
      //console.log("-------------------------------------------");
      //console.log(this.surveyGlobal);
      if ( this.surveyGlobal==null||this.surveyGlobal==undefined )
        {
            return {};
        }
        else

        {
          //console.log(this.surveyGlobal);
          //console.log("-------------------------------------------");


          var values=this.surveyGlobal.map(a => a.attendeeAnswer);
      var tags=this.surveyGlobal.map(a => a.questionTag);


            return {
    series: [
      {
        name: 'TEAM',
        data: values,
      },
      
    ],
    chartOptions: {
      chart: {
        toolbar: {
          show: true,
        },
        markers: {
    size: 5,
    hover: {
      size: 10
    }
  },
        dropShadow: {
          enabled: false,
          blur: 8,
          left: 1,
          top: 1,
          opacity: 0.2,
        },
      },
      legend: {
        show: true,
        fontSize: '14px',
        fontFamily: 'Montserrat',
      },
      yaxis: {
        show: true,
      },
      colors: [chartColors.donut.series1, chartColors.donut.series3],
      xaxis: {
        categories: tags,
      },
      fill: {
        opacity: [1, 0.8],
      },
      stroke: {
        show: true,
        width: 0,
      },
      markers: {
        size: 0,
      },
      grid: {
        show: false,
      },
    },
  }
        }
       

    },


    selectedChart()
    {
      if ( this.selectedItem==null||this.selectedItem==undefined||this.selectedItem==""||this.selectedText=="")
        {
            return {};
        }
        else

        {
          //console.log(this.selectedItem);

          var values=this.selectedItem.map(a => a.attendeeAnswer);
      var tags=this.selectedItem.map(a => a.questionTag);


            return {
    series: [
      {
        name: 'TEAM',
        data: values,
      },
      
    ],
    chartOptions: {
      chart: {
        toolbar: {
          show: true,
        },
        dropShadow: {
          enabled: false,
          blur: 8,
          left: 1,
          top: 1,
          opacity: 0.2,
        },
      },
      legend: {
        show: true,
        fontSize: '14px',
        fontFamily: 'Montserrat',
      },
      yaxis: {
        show: true,
      },
      colors: [chartColors.donut.series1, chartColors.donut.series3],
      xaxis: {
        categories: tags,
      },
      fill: {
        opacity: [1, 0.8],
      },
      stroke: {
        show: false,
        width: 0,
      },
      markers: {
        size: 0,
      },
      grid: {
        show: false,
      },
    },
  }
        }
       

    },
    selectedTitle:
    {
      get() {
        return  this.selectedItem==""||this.selectedItem==null||this.selectedItem==undefined?'': this.selectedText; 
      },
      
       
    },
    selectedAverageTitle:
    {
      get() {
        return  this.selectedItem==""||this.selectedItem==null||this.selectedItem==undefined||this.selectedText==""?'Average':  this.selectedText+   '& Average'  ;
      },
      
       
    },
   
    selected:
    {
      get() {
        this.chartKey += 1; 
        if ( this.selectedItem==null||this.selectedItem==undefined||this.selectedItem=="")
        {
          if (this.surveyAbs==null||this.surveyAbs==undefined) 
            {
              return {}
            } else

            {
              return {};
            }
        } else return  this.selectedText;
      },
      set(newValue) {
        // Note: we are using destructuring assignment syntax here.
        //console.log('selectedITem');
        //console.log(newValue);
       this.selectedItem = this.surveyAbs[newValue];
       this.selectedText=newValue;
       //console.log(this.selectedItem);
       this.chartKey += 1; 
       
       var title=this.selectedText;
      var values=this.selectedItem.map(a => a.attendeeAnswer);
      var tags=this.selectedItem.map(a => a.questionTag);
      //console.log("TAGSS");
      //console.log(this.selectedItem[0].id );
      this.getItemResult(this.selectedItem[0].id);

      

    var  series= [
      {
        name: title,
        data: values,
      },
      {
        name: 'AVERAGE',
        data: [1, 5, 3, 2, 2, 4, 2, 1,1,3],
      },
     
    ];
      // this.$refs.realtimeChart.updateSeries(series, false, true);
      }
       
    },

    options()
    {
      //console.log("TAGSS");
      //console.log(this.surveyAbs);

      if (this.surveyAbs==null||this.surveyAbs==undefined) return [];
    
    const iterator1 = Object.keys(this.surveyAbs)  ;
    //console.log(iterator1);

    const d=this.surveyAbs;
 
         return iterator1;
    },


    personelChart()
    {

      var values=this.surveyAbs.map(a => a.value);
      var tags=this.surveyAbs.map(a => a.tag);

      return {
    series: [
      {
        name: 'TEAM',
        data: values,
      },
      
    ],
    chartOptions: {
      chart: {
        toolbar: {
          show: false,
        },
        dropShadow: {
          enabled: false,
          blur: 8,
          left: 1,
          top: 1,
          opacity: 0.2,
        },
      },
      legend: {
        show: true,
        fontSize: '14px',
        fontFamily: 'Montserrat',
      },
      yaxis: {
        show: true,
      },
      colors: [chartColors.donut.series1, chartColors.donut.series3],
      xaxis: {
        categories: tags,
      },
      fill: {
        opacity: [1, 0.8],
      },
      stroke: {
        show: false,
        width: 0,
      },
      markers: {
        size: 0,
      },
      grid: {
        show: false,
      },
    },
  }
    },
    radarChart()
    {

  if ( this.selectedItem==null||this.selectedItem==undefined||this.selectedItem==""||this.selectedText=="")
        {
          //console.log("returned null");
            return {};
        }
         


      var title=this.selectedText;
      var values=this.selectedItem.map(a => a.attendeeAnswer);
      var tags=this.selectedItem.map(a => a.questionTag);
    


      var globalValues=this.surveyGlobal.map(a => a.attendeeAnswer);
      var globalTags=this.surveyGlobal.map(a => a.questionTag);

  
        return {
    series: [
      {
        name: title,
        data: values,
      },
      {
        name: 'AVERAGE',
        data: globalValues,
      },
     
    ],
    chartOptions: {
      chart: {
        toolbar: {
          show: true,
        },
        dropShadow: {
          enabled: false,
          blur: 8,
          left: 1,
          top: 1,
          opacity: 0.2,
        },
      },
      legend: {
        show: true,
        fontSize: '14px',
        fontFamily: 'Montserrat',
      },
      yaxis: {
        show: true,
      },
      colors: [chartColors.donut.series1, chartColors.donut.series3],
      xaxis: {
        categories:tags ,
      },
      fill: {
        opacity: [1, 0.8],
      },
      stroke: {
        show: false,
        width: 0,
      },
      markers: {
        size: 0,
      },
      grid: {
        show: false,
      },
    },
  }
    }

  },
   data() {
    return {
     
      selectedText:"" ,
      chartKey:1,

      surveytitle:'',
       
     
      goalOverviewRadialBar: {
        chart: {
          height: 245,
          type: 'radialBar',
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: [$goalStrokeColor2],
        plotOptions: {
          radialBar: {
            offsetY: -10,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: '77%',
            },
            track: {
              background: $strokeColor,
              strokeWidth: '50%',
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                color: $textHeadingColor,
                fontSize: '2.86rem',
                fontWeight: '600',
              },
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: [$themeColors.success],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: 'round',
        },
        grid: {
          padding: {
            bottom: 30,
          },
        },
      },
    }
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-survey-results'
    //console.log("survey results setup");
  const surveyData = ref(null);
  const surveyAbs=ref(null);
  const selectedItem=ref(null);
  const selectedText=ref(null);
  const surveyGlobal=ref(null);
  const surveySubjectsAbs=ref(null);
  const surveyAttendeeResults=ref(null);
  const attendteAnswers=ref(null);
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, resultsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

   
  

      

    var surveyId=router.currentRoute.query.s ;
 

  store.dispatch('app-survey-results/getSurveyAbsGlobal', { id: surveyId})
      .then(response => {
   //    var course=JSON.parse(JSON.stringify(response.data));
      //  const { course  } = response.data
      surveyGlobal.value =  response.data.data;
       
        
        
        })
      .catch(error => {
        if (error.response.status === 404) {
          surveyGlobal.value = undefined
        }
      })

      const getItemResult= (itemid) => { store.dispatch('app-survey-results/getAttendeResultByItemId', { id: surveyId,itemid:itemid})
      .then(response => {
        //console.log("incoming attendee results",itemid);
   //    var course=JSON.parse(JSON.stringify(response.data));
      //  const { course  } = response.data
      attendteAnswers.value =  response.data.data;
       
        
        
        })
      .catch(error => {
        if (error.response.status === 404) {
          attendteAnswers.value = undefined
        }
      });
    }

    

      store.dispatch('app-survey-results/getSurveyAbs', { id: surveyId})
      .then(response => {
   //    var course=JSON.parse(JSON.stringify(response.data));
      //  const { course  } = response.data
      surveyAbs.value =  response.data.data;
       //console.log("received surveyAbs information");
       //console.log( surveyAbs.value );

       const iterator1 = Object.keys(surveyAbs.value)  ;
          //console.log(iterator1);
       

      var firstItem =iterator1[0];
     var first=surveyAbs.value[firstItem];
 
       //console.log("==========");
      selectedItem.value =first;
      selectedText.value=firstItem;
              //this.selectedItem =   this.surveyAbs[0];
              
       
    
        
        })
      .catch(error => {
        if (error.response.status === 404) {
          surveyAbs.value = undefined
        }
      })

      store.dispatch('app-survey-results/getSurveyAttendeResults', { id: surveyId})
      .then(response => {
   //    var course=JSON.parse(JSON.stringify(response.data));
      //  const { course  } = response.data
      surveyAttendeeResults.value =  response.data.data[0];
       //console.log("received getSurveyAttendeResults information");
       //console.log(surveyAttendeeResults.value);
  
       
  
        
        })
      .catch(error => {
        //console.log("ERRORRR");
        //console.log(error);
        if (error.response.status === 404) {
          
        }
      })


      store.dispatch('app-survey-results/fetchSurvey', { id: surveyId})
      .then(response => {
   //    var course=JSON.parse(JSON.stringify(response.data));
      //  const { course  } = response.data
        surveyData.value =  response.data.data;
       //console.log("received survey information");
       
        
        
        })
      .catch(error => {
        if (error.response.status === 404) {
          surveyData.value = undefined
        }
      })

    return {

      // Sidebar
      surveyAbs,
      surveyData,
      surveySubjectsAbs,
      surveyAttendeeResults,
      surveyGlobal,
      selectedItem,
      getItemResult,
      attendteAnswers
            
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';

  @import '@core/scss/vue/libs/vue-select.scss';
  </style>