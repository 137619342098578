<template>
  <b-card :title="title">
    <div   v-if="!apexChartData"> 
      Not enough data to display chart
    </div>
    <vue-apex-charts
    v-if="apexChartData"
      type="radar"
      height="400"
      :options="apexChartData.chartOptions"
      :series="apexChartData.series"
    />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts' 

export default {
  components: {
    BCard,
    VueApexCharts,
  },
  
   props: {
    title:'',
    apexChartData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      
    }
  },
}
</script>
